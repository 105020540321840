import React from "react";

import Layout from "../components/layout";
import PageInfo from "../components/pageInfo";

const Policy = ({ pageContext: { title, body } }) => {
  return (
    <Layout>
      <PageInfo title={title} />

      <h1 className="text-6xl text-center mb-10 font-new-tegomin">{title}</h1>

      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </Layout>
  );
};

export default Policy;
